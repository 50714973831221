// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

// Supports weights 300-800
import '@fontsource-variable/open-sans';
import './src/sass/style.scss'

// Supports weights 100-900
import '@fontsource-variable/montserrat';

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;